<template>
  <div class="question-type">
    <!-- 单选题 -->
    <type-0
      v-if="dataObj.type == 0"
      :dataObj="dataObj"
    ></type-0>
    <!-- 多选题 -->
    <type-1
      v-if="dataObj.type == 1"
      :dataObj="dataObj"
    ></type-1>
    <!-- 判断题 -->
    <type-2
      v-if="dataObj.type == 2"
      :dataObj="dataObj"
    ></type-2>
    <!-- 填空题 -->
    <type-3
      v-if="dataObj.type == 3"
      :dataObj="dataObj"
    ></type-3>
    <!-- 画图题 -->
    <type-4
      v-if="dataObj.type == 4"
      :dataObj="dataObj"
    ></type-4>
    <!-- 问答题 -->
    <type-5
      v-if="dataObj.type == 5"
      :dataObj="dataObj"
    ></type-5>
    <!-- 阅读题 -->
    <type-6
      v-if="dataObj.type == 6"
      :dataObj="dataObj"
    ></type-6>
    <!-- 写作题 -->
    <type-7
      v-if="dataObj.type == 7"
      :dataObj="dataObj"
    ></type-7>
    <!-- 字词认读 -->
    <type-8
      v-if="dataObj.type == 8"
      :dataObj="dataObj"
    ></type-8>
    <!-- 字形辨认 -->
    <type-9
      v-if="dataObj.type == 9"
      :dataObj="dataObj"
    ></type-9>
    <!-- 词语释义 -->
    <type-10
      v-if="dataObj.type == 10"
      :dataObj="dataObj"
    ></type-10>
    <!-- 多音多义 -->
    <type-11
      v-if="dataObj.type == 11"
      :dataObj="dataObj"
    ></type-11>
    <!-- 速记 -->
    <type-12
      v-if="dataObj.type == 12"
      :dataObj="dataObj"
    ></type-12>
    <!-- 拼写 -->
    <type-13
      v-if="dataObj.type == 13"
      :dataObj="dataObj"
    ></type-13>
    <!-- 听力 -->
    <type-14
      v-if="dataObj.type == 14"
      :dataObj="dataObj"
    ></type-14>
  </div>
</template>

<script>
import type0 from "./type0.vue";
import type1 from "./type1.vue";
import type2 from "./type2.vue";
import type3 from "./type3.vue";
import type4 from "./type4.vue";
import type5 from "./type5.vue";
import type6 from "./type6.vue";
import type7 from "./type7.vue";
import type8 from "./type8.vue";
import type9 from "./type9.vue";
import type10 from "./type10.vue";
import type11 from "./type11.vue";
import type12 from "./type12.vue";
import type13 from "./type13.vue";
import type14 from "./type14.vue";
export default {
  name: "questionType",
  components: {
    type0,
    type1,
    type2,
    type3,
    type4,
    type5,
    type6,
    type7,
    type8,
    type9,
    type10,
    type11,
    type12,
    type13,
    type14,
  },
  props: {
    currentIndex:{
      type:[Number,String],
      default:0
    },
    totalNum:{
      type:[Number,String],
      default:0
    },
    questionData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    questionData: {
      handler: function (val) {
        this.dataObj = val;
      },
      immediate: true,
    },
  },
  data() {
    return {
      dataObj: {},
    };
  },
};
</script>

<style>
</style>
<template>
  <div class="word-shorthand">
    <div class="short-hand">
      <div
        class="area-box"
        :class="{ areaBox1: ['单词集中突破'].includes(currentTitle) }"
      >
        <div class="area-box-main">
          <div class="word">
            <span class="word-box">
              <span class="word-txt">{{ dataObj.testpapecontent }}</span>
            </span>
          </div>
          <div class="voice">
            <div
              class="voice-item"
              v-if="dataObj.mubiaozi"
              @click="playVoice(dataObj.hearing)"
            >
              <span>{{ dataObj.mubiaozi }}</span>
              <svg-icon
                icon-class="voice"
                class="icon"
                :class="{ 'icon-active': audioPlay }"
                v-if="dataObj.hearing"
              ></svg-icon>
            </div>
          </div>
          <div class="des">
            <div
              v-if="dataObj.showResult"
              style="margin-bottom: 0.2rem"
              v-html="dataObj.answerminutes"
            ></div>

            <van-image
              fit="cover"
              class="word-img"
              :src="setSourceUrl(dataObj.image)"
            />
            <div class="example" v-if="dataObj.analysiscontent">
              <span class="tit">eg.例子</span>
            </div>
            <div class="prase">
              <p
                v-if="dataObj.analysiscontent"
                v-html="dataObj.analysiscontent"
              ></p>
              <p v-if="dataObj.showResult" v-html="dataObj.rightkey"></p>
            </div>
          </div>
        </div>
      </div>
      <div class="btns">
        <div class="btn btn-right" @click="changeQuestion(1)" v-if="nextBtn">
          正确
        </div>
        <div
          class="btn btn-right"
          :class="{ disable: unKnown }"
          @click="yiZhangWo"
          v-else
        >
          已掌握
        </div>
        <div
          class="btn btn-error"
          :class="{ disable: unKnown }"
          @click="changeQuestion(2)"
        >
          {{ nextBtn ? "错误" : "不认识" }}
        </div>
      </div>
    </div>

    <div class="audio-box">
      <audio
        id="audio"
        ref="audio"
        :src="setSourceUrl(audioSrc)"
        @ended="played"
      >
        您的浏览器不支持 audio 标签。
      </audio>
    </div>
  </div>
</template>

<script>
import { setSourceUrl } from "@/utils/public";
export default {
  name: "questionType12",
  props: {
    dataObj: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    dataObj: {
      handler: function (val) {
        val.testpapecontent = val?.testpapecontent
          ?.replace(/<\/?.+?>/g, "")
          ?.replace(/ /g, "");
        this.privateData = val;
        if(!val.showResult){
          this.playVoice(this.dataObj.hearing);
        }
        
      },
      immediate: true,
    },
  },
  data() {
    return {
      totalNum: 0,
      inputIndex: 0,
      currentIndex: 0,
      audioPlay: false,
      showResult:false,
      audioSrc: "",
      nextBtn: false,
      unKnown: false,
      audioObj: null,
    };
  },
  created() {
    this.totalNum = this.$store.getters["common/getTotalQuestion"];
  },
  mounted() {
    this.audioObj = this.$refs["audio"];
    this.audioSrc = this.privateData.hearing;
    this.playVoice(this.dataObj.hearing);
  },
  methods: {
    setSourceUrl,
    yiZhangWo() {
      if (this.unKnown) {
        return false;
      }
      this.showResult = true;
      this.$set(this.dataObj, "showResult", true);
      this.nextBtn = true;
      // this.playVoice(this.dataObj.hearing);
    },
    changeQuestion(n) {
      if (this.unKnown) {
        return false;
      }
      if (n == 1) {
        if (this.currentIndex < this.totalNum - 1) {
          this.currentIndex = ++this.currentIndex;
          this.nextBtn = false;
          this.$eventBus.$emit("changeQuestion", this.currentIndex);
          // this.audioObj.pause();
          // this.audioPlay = false;
          // this.audioSrc = this.privateData.hearing;
          // setTimeout(() => {
          //   this.audioPlay = true;
          //   this.audioObj.play();
          // }, 1);
        } else {
          this.$dialog
            .confirm({
              message: "已是最后一个，再做一次？",
            })
            .then(() => {
              this.currentIndex = 0;
              this.nextBtn = false;
              this.audioPlay = false;
              this.$eventBus.$emit("repeatOperate");
            });
        }
      }
      if (n == 2) {
        this.$set(this.privateData, "showResult", true);
        this.showResult = true;
        if (!this.nextBtn) {
          this.unKnown = true;
          //this.playVoice(this.dataObj.hearing);
          setTimeout(() => {
            this.nextBtn = false;
            this.unKnown = false;
            this.$eventBus.$emit("unKnowWord", this.currentIndex);
          }, 3000);
        } else {
          this.nextBtn = false;
          this.$eventBus.$emit("unKnowWord", this.currentIndex);
        }
      }
    },

    playVoice(src) {
      if (src) {
        this.audioPlay = true;
        this.audioSrc = src;
        //this.$set(this.dataObj, "play", !this.dataObj.play);
        if (this.audioObj) {
          setTimeout(()=>{
            this.audioObj.play();
          },1)
        } else {
         // this.audioObj.pause();
        }
      }
    },
    played() {
      this.audioPlay = false;
      //this.$set(this.dataObj, "play", false);
    },
  },
};
</script>


<style lang="scss" scoped>
.audio-box {
  width: 0;
  height: 0;
  overflow: hidden;
}
.area-box {
  display: flex;
  align-items: center;

  padding: 0.3rem 0;
  height: calc(100vh - 3rem);
  border: 0.01rem #dcdcdc solid;
  border-radius: 0.08rem;

  &-main {
    width: 100%;

    padding: 0 0.3rem;
    max-height: 100%;
    overflow-y: auto;
    .example {
      display: block;
      padding: 0.32rem 0;
      .tit {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1.36rem;
        height: 0.44rem;
        background: #2db79a;
        border-radius: 0.22rem;
        font-size: 0.24rem;
        color: #fff;
      }
    }
    .des {
      font-size: 0.28rem;
      p {
        margin-bottom: 0.16rem;
      }
      .word-img {
        width: 100%;
      }
    }
    .voice {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0.3rem;
      .voice-item {
        display: flex;
        align-items: center;
        height: 0.64rem;
        margin: 0 0.15rem;
        border-radius: 0.32rem;
        padding: 0.15rem 0.2rem;
        font-size: 0.28rem;
        color: #323233;
        background: #f4f5f7;
        span {
          color: #909090;
        }
        .icon {
          margin-left: 0.1rem;
          &-active {
            color: #27b295;
          }
        }
      }
    }
    .word {
      display: flex;
      justify-content: center;
      margin-bottom: 0.2rem;
      .word-box {
        position: relative;
        color: #323233;
        &::before {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0.16rem;
          content: "";
          height: 0.16rem;
          background: #e1f5f1;
        }
        .word-txt {
          position: relative;
          z-index: 2;
          font-size: 0.6rem !important;
        }
      }
    }
    .des {
      img {
        width: 100%;
      }
    }
  }
}
.areaBox1 {
  height: calc(100vh - 3.8rem);
}
.area-box-5 {
  height: calc(100vh - 1.8rem);
}
.btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.24rem;
    height: 0.8rem;
    border-radius: 0.4rem;
    font-size: 0.3rem;
    color: #fff;
    margin-top: 0.3rem;
    &-right {
      background: #27b295;
    }
    &-error {
      background: #ff545c;
    }
    &:active {
      opacity: 0.8;
    }
  }
  .disable {
    opacity: 0.8;
  }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"question-content",attrs:{"id":"image-preview"}},[_c('navBar',{scopedSlots:_vm._u([(1 < _vm.computeTotal && !_vm.isLoading)?{key:"right",fn:function(){return [(
          !_vm.isLoading &&
          _vm.questionData.list.length &&
          !['8', '9', '10', '11', '12', '13', '14'].includes(
            _vm.questionData.list[_vm.currentQuestion].type
          )
        )?[(
            _vm.fromPage == 'errorbook' ||
            ['古诗文必备', '拼写'].includes(_vm.currentTitle)
          )?[(_vm.computeTotal && 1 < _vm.computeTotal)?_c('div',{staticClass:"common-step"},[_c('span',{staticClass:"current"},[_vm._v(_vm._s(_vm.currentQuestion + 1))]),_c('span',{staticClass:"total"},[_vm._v("/"+_vm._s(_vm.computeTotal))])]):_vm._e()]:[(_vm.computeTotal && 1 < _vm.accountRequest)?_c('div',{staticClass:"common-step"},[_c('span',{staticClass:"current"},[_vm._v(_vm._s(_vm.currentQuestionModle + 1))]),_c('span',{staticClass:"total"},[_vm._v("/"+_vm._s(_vm.accountRequest))])]):_vm._e()]]:[(_vm.computeTotal && 1 < _vm.computeTotal)?_c('div',{staticClass:"common-step"},[_c('span',{staticClass:"current"},[_vm._v(_vm._s(_vm.currentQuestion + 1))]),_c('span',{staticClass:"total"},[_vm._v("/"+_vm._s(_vm.computeTotal))])]):_vm._e()]]},proxy:true}:null],null,true)},[_c('span',[_vm._v(_vm._s(_vm.currentTitle))])]),(_vm.isLoading)?_c('div',{staticClass:"loading-box"},[_vm._v("数据加载中...")]):_vm._e(),(1 < _vm.computeTotal)?_c('div',{staticClass:"common-progress"},[_c('div',{staticClass:"con",style:(("width:" + (((_vm.currentQuestion + 1) / _vm.computeTotal) * 100) + "%"))})]):_vm._e(),(_vm.questionData.list)?_c('div',{staticClass:"content-body",style:(("bottom:" + (['多音多义', '速记', '拼写'].includes(_vm.currentTitle) ? '0' : '1rem')))},[_c('div',{staticClass:"body-con"},[(_vm.questionData.list[_vm.currentQuestion].yueducontent)?_c('div',{staticClass:"question-article",domProps:{"innerHTML":_vm._s(_vm.questionData.list[_vm.currentQuestion].yueducontent)}}):_vm._e(),_c('questionType',{attrs:{"questionData":_vm.questionData.list[_vm.currentQuestion]}})],1),(!['速记', '拼写'].includes(_vm.currentTitle))?_c('div',{staticClass:"question-handle"},[(1 < _vm.computeTotal)?_c('div',{staticClass:"btn",on:{"click":function($event){return _vm.changeQuestion(1)}}},[_vm._v(" 上一题 ")]):_c('span'),(1 < _vm.computeTotal)?_c('div',{staticClass:"btn",on:{"click":function($event){return _vm.changeQuestion(0)}}},[_vm._v(" 下一题 ")]):_vm._e(),(
          _vm.computeTotal &&
          !_vm.isLoading &&
          _vm.questionData.list[_vm.currentQuestion].hearing &&
          !['速记', '拼写'].includes(_vm.currentTitle)
        )?_c('div',{staticClass:"btn",class:{ 'jiexi-btn': _vm.isHearing },on:{"click":_vm.playHearing}},[_vm._v(" "+_vm._s(_vm.isHearing ? "暂停" : "播放")+" ")]):_vm._e(),(
          !['8', '9', '11'].includes(_vm.questionData.list[_vm.currentQuestion].type)
        )?_c('div',{staticClass:"btn jiexi-btn",on:{"click":_vm.showParseAction}},[_vm._v(" 查看解析 ")]):_vm._e()]):_vm._e()]):_vm._e(),_c('audio',{ref:"errorAudio",staticClass:"status-vioce",attrs:{"src":_vm.errorVoice,"controlslist":"nodownload","controls":"controls"}}),_c('audio',{ref:"properAudio",staticClass:"status-vioce",attrs:{"src":_vm.properVoice,"controlslist":"nodownload","controls":"controls"}}),(!_vm.isLoading && !_vm.isEmpty)?_c('audio',{ref:"hearingAudio",staticClass:"status-vioce",attrs:{"src":_vm.setSourceUrl(_vm.questionData.list[_vm.currentQuestion].hearing),"controlslist":"nodownload","controls":"controls","preload":"true"},on:{"ended":function($event){_vm.isHearing = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
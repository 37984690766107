<template>
  <div class="history-page">
    <navBar>
      <span class="title">学习记录</span>
    </navBar>
    <div class="page-content" style="padding:.28rem">
      <courseList :courseData="courseData" v-if="courseData.length"></courseList>
    </div>
  </div>
</template>

<script>
import courseList from "@/components/courseList.vue";
export default {
  name: "moreHistory",
  components: {
    courseList,
  },
  data() {
    return {
      courseData:[]
    };
  },
  created() {
    this.getLastLearn();
  },
  mounted() {
    
  },
  methods: {
    getLastLearn() {
      let that=this;
      that.$api.getLastLearn({
        type:1
      }).then((res) => {
        let resData = res.data || [];

        // 转换后的二维数组
        let newData = Object.values(
          resData.reduce((res, item) => {
            res[item.maxtypename]
              ? res[item.maxtypename].push(item)
              : (res[item.maxtypename] = [item]);
            return res;
          }, {})
        );
        that.courseData = newData.flat(1);
      });
  
    }
  },
};
</script>

<style lang="scss" scoped>
  .history-page{
    padding-top: 1.08rem;
  }
</style>
<template>
  <div class="question-content" id="image-preview">
    <navBar>
      <span>{{ currentTitle }}</span>
      <template v-slot:right v-if="1 < computeTotal && !isLoading">
        <template
          v-if="
            !isLoading &&
            questionData.list.length &&
            !['8', '9', '10', '11', '12', '13', '14'].includes(
              questionData.list[currentQuestion].type
            )
          "
        >
          <template
            v-if="
              fromPage == 'errorbook' ||
              ['古诗文必备', '拼写'].includes(currentTitle)
            "
          >
            <div class="common-step" v-if="computeTotal && 1 < computeTotal">
              <span class="current">{{ currentQuestion + 1 }}</span>
              <span class="total">/{{ computeTotal }}</span>
            </div>
          </template>
          <template v-else>
            <div class="common-step" v-if="computeTotal && 1 < accountRequest">
              <span class="current">{{ currentQuestionModle + 1 }}</span>
              <span class="total">/{{ accountRequest }}</span>
            </div>
          </template>
        </template>

        <template v-else
          ><div class="common-step" v-if="computeTotal && 1 < computeTotal">
            <span class="current">{{ currentQuestion + 1 }}</span>
            <span class="total">/{{ computeTotal }}</span>
          </div></template
        >
      </template>
    </navBar>

    <div class="loading-box" v-if="isLoading">数据加载中...</div>

    <div class="common-progress" v-if="1 < computeTotal">
      <div
        class="con"
        :style="`width:${((currentQuestion + 1) / computeTotal) * 100}%`"
      ></div>
    </div>

    <div
      class="content-body"
      v-if="questionData.list"
      :style="`bottom:${
        ['多音多义', '速记', '拼写'].includes(currentTitle) ? '0' : '1rem'
      }`"
    >
      <div class="body-con">
        <div
          class="question-article"
          v-if="questionData.list[currentQuestion].yueducontent"
          v-html="questionData.list[currentQuestion].yueducontent"
        ></div>
        <questionType
          :questionData="questionData.list[currentQuestion]"
        ></questionType>
      </div>

      <div
        class="question-handle"
        v-if="!['速记', '拼写'].includes(currentTitle)"
      >
        <div class="btn" @click="changeQuestion(1)" v-if="1 < computeTotal">
          上一题
        </div>
        <span v-else></span>
        <div class="btn" @click="changeQuestion(0)" v-if="1 < computeTotal">
          下一题
        </div>

        <div
          class="btn"
          :class="{ 'jiexi-btn': isHearing }"
          v-if="
            computeTotal &&
            !isLoading &&
            questionData.list[currentQuestion].hearing &&
            !['速记', '拼写'].includes(currentTitle)
          "
          @click="playHearing"
        >
          {{ isHearing ? "暂停" : "播放" }}
        </div>

        <div
          class="btn jiexi-btn"
          v-if="
            !['8', '9', '11'].includes(questionData.list[currentQuestion].type)
          "
          @click="showParseAction"
        >
          查看解析
        </div>
      </div>
    </div>

    <audio
      :src="errorVoice"
      controlslist="nodownload"
      controls="controls"
      ref="errorAudio"
      class="status-vioce"
    ></audio>

    <audio
      :src="properVoice"
      controlslist="nodownload"
      controls="controls"
      ref="properAudio"
      class="status-vioce"
    ></audio>

    <audio
      v-if="!isLoading && !isEmpty"
      :src="setSourceUrl(questionData.list[currentQuestion].hearing)"
      controlslist="nodownload"
      controls="controls"
      ref="hearingAudio"
      preload="true"
      @ended="isHearing = false"
      class="status-vioce"
    ></audio>
  </div>
</template>

<script>
import questionType from "@/views/commonContent/questionType.vue";
import {
  setSourceUrl,
  html2string,
  deepClone,
  computeOptionjson,
  formatAddPointWords,
  formatImg,
  middleImg,
  setMathBa,
  palindrome,
} from "@/utils/public";
export default {
  name: "questionContent",
  components: { questionType },
  data() {
    return {
      errorVoice: require("@/assets/audio/error.mp3"),
      properVoice: require("@/assets/audio/proper.mp3"),
      properAudio: null,
      errorAudio: null,
      hearingAudio: null,
      isEmpty: false,
      isHearing: false,
      isLoading: false,
      totalRequest: -1,
      accountRequest: 0,
      currentQuestion: 0,
      currentQuestionModle: 0,
      questionData: {},
      dataSource: {},
      requestData: [],
      fromPage: "",
      currentCourse: "",
    };
  },
  watch: {
    accountRequest: function (val) {
      if (val == this.totalRequest) {
        let questList = [];
        this.isLoading = false;
        this.requestData.forEach((item) => {
          questList = [...questList, ...item];
        });
        this.$set(this.questionData, "list", questList);
        this.dataSource = this.deepClone(this.questionData);
        this.$nextTick(() => {
          this.setImgStyle();
          this.hearingAudio = this.$refs["hearingAudio"];
        });
      }
    },
    currentQuestion: function (val) {
      let num = this.questionData.list[val]?.parentNum;
      this.currentQuestionModle = num;
    },
    currentQuestionModle: function (val) {
      console.log(val);
      let dom = window.document.getElementById("image-preview");
      let scrollDom = dom.getElementsByClassName("body-con")[0];
      scrollDom.scrollTo(0, 0);
    },
  },
  computed: {
    computeTotal: function () {
      let len = this.questionData?.list?.length || 0;
      this.$store.commit("common/setTotalQuestion", len);
      return len;
    },
    isWordsQuestion: function () {
      let handler = false;

      if (this.questionData?.list?.length) {
        if (
          this.questionData.list[this.currentQuestion].type == 12 ||
          this.questionData.list[this.currentQuestion].type == 13
        ) {
          handler = true;
        }
      }
      return handler;
    },
  },
  created() {
    this.currentCourse = this.$store.getters["common/getCurrentSuject"];
    this.fromPage = this.$route.query.from || "";
    if (this.fromPage == "errorbook") {
      this.initCommonErrorQuestion();
    } else {
      this.initCommonQuestion();
    }
  },
  mounted() {
    this.addHistory();
    this.properAudio = this.$refs["properAudio"];
    this.errorAudio = this.$refs["errorAudio"];

    this.$eventBus.$on("changeQuestion", (val) => {
      this.currentQuestion = val;
    });
    this.$eventBus.$on("repeatOperate", () => {
      this.currentQuestion = 0;
      this.questionData = this.deepClone(this.dataSource);
    });
    this.$eventBus.$on("unKnowWord", (val) => {
      this.currentQuestion = val;
      let dataList = this.questionData.list;
      let unKuowObj = dataList[val];
      let unknowNum = ++unKuowObj.unknowNum;
      let step = 0;
      step = val + unknowNum + 1;
      if (dataList.length < step) {
        step = dataList.length;
      }
      unKuowObj.unknowNum = unknowNum;
      dataList.splice(val, 1);
      dataList.splice(step, 0, unKuowObj);

      this.$set(this.questionData, "list", dataList);
    });
  },
  methods: {
    middleImg,
    setSourceUrl,
    html2string,
    deepClone,
    computeOptionjson,
    formatAddPointWords,
    formatImg,
    setMathBa,
    palindrome,
    addHistory() {
      this.$api
        .addLearnHistory({
          course_id: this.$route.query.courseId,
          coursechapters_id: this.$route.query.id,
          coursechapters_title: this.currentTitle,
          rateoflearning: "0",
          learning_info: this.$store.getters["common/getUnitNav"],
          active: this.$route.query.active,
          showToast: 1,
        })
        .then();
    },
    setImgStyle() {
      this.MathJax.MathQueue("math-formula"); //传入组件id，让组件被MathJax渲染
    },
    // middleImg(str) {
    //   let content = String(str || "");
    //   let content1 = content.replace(/&lt;cimg&gt;/g, '<span class="cimg">');
    //   let content2 = content1.replace(/&lt;\/cimg&gt;/g, "</span>");

    //   return content2;
    // },
    playHearing() {
      this.isHearing = !this.isHearing;

      if (this.isHearing) {
        setTimeout(() => {
          this.hearingAudio.play();
        }, 0);
      } else {
        this.hearingAudio.pause();
      }
    },
    showParseAction() {
      let showParse = this.questionData.list[this.currentQuestion].showParse;
      if(showParse){
        return false
      }
      let answerStatus = 1;
      let questionId = this.questionData.list[this.currentQuestion].id || "";
      let optionJson = this.questionData.list[this.currentQuestion].optionjson;
      let rightKey = this.html2string(
        this.questionData.list[this.currentQuestion].rightkey
      );
      let type = this.questionData.list[this.currentQuestion].type;
      let answerIdx = String(
        this.questionData.list[this.currentQuestion].answerIdx
      );
      let answerVal = String(
        this.questionData.list[this.currentQuestion].answerVal
      );
      if (type < 3) {
        answerVal = answerVal.split("").sort().join("");
        optionJson.forEach((item, index) => {
          if (rightKey.includes(item.value)) {
            item.status = 2;
          } else {
            if (answerIdx.includes(index)) {
              item.status = 3;
            }
          }
        });
        this.$set(
          this.questionData.list[this.currentQuestion],
          "optionjson",
          optionJson
        );
        if (answerVal == rightKey) {
          answerStatus = 2;
        } else {
          answerStatus = 3;
        }
        if (answerStatus == 2) {
          this.properAudio.play();
        }
        if (answerStatus == 3) {
          this.errorAudio.play();
          this.$api.addErrorQuestion({ testpapeid: questionId }).then((res) => {
            console.log(res);
          });
        }
      }

      this.$set(
        this.questionData.list[this.currentQuestion],
        "showParse",
        true
      );
      this.$nextTick(() => {
        this.setImgStyle();
      });
    },
    changeQuestion(n) {
      switch (n) {
        case 0: {
          if (this.currentQuestion < this.questionData.list.length - 1) {
            this.currentQuestion = ++this.currentQuestion;
          } else {
            this.$notify({
              type: "warning",
              message: "已经是最后一题了！",
            });
          }
          break;
        }
        case 1: {
          if (this.currentQuestion) {
            this.currentQuestion = --this.currentQuestion;
          } else {
            this.$notify({
              type: "warning",
              message: "已经是第一题了！",
            });
          }
          break;
        }
        default: {
          break;
        }
      }
      this.$nextTick(() => {
        this.setImgStyle();
      });
    },
    initCommonErrorQuestion() {
      this.isLoading = true;
      this.$api
        .errorQuestionContent({
          papetypeid: this.$route.query.id,
          page: 1,
          rows: 1000,
        })
        .then((res) => {
          let resData = res.data.list || [];
          if (resData.length) {
            resData.forEach((item, index) => {
              if (item) {
                item.answerVal = "";
                item.answerIdx = "";
                item.questionIdx = [0, index];
                item.showParse = false;
                item.showAnswer = false;
                if (["语文", "英语"].includes(this.currentCourse.name)) {
                  item.optionjson = this.computeOptionjson(
                    this.formatAddPointWords(item.optionjson)
                  );
                  item.testpapecontent = this.formatAddPointWords(
                    item.testpapecontent
                  );
                  item.rightkey = this.formatAddPointWords(item.rightkey);
                  item.analysiscontent = this.formatAddPointWords(
                    item.analysiscontent
                  );
                } else {
                  item.optionjson = this.computeOptionjson(item.optionjson);
                }
                item.testpapecontent = this.formatImg(item.testpapecontent);
                item.rightkey = this.formatImg(item.rightkey);
                item.analysiscontent = this.formatImg(item.analysiscontent);
                item.unknowNum = 0;
                item.testpapecontent = this.middleImg(item.testpapecontent);
                item.rightkey = this.middleImg(item.rightkey);
                item.analysiscontent = this.middleImg(item.analysiscontent);
                item.testpapecontent = this.setMathBa(item.testpapecontent);
                item.rightkey = this.setMathBa(item.rightkey);
                item.analysiscontent = this.setMathBa(item.analysiscontent);
                item.testpapecontent = this.setMathBa(item.testpapecontent);
              }
            });
          }

          let listData = this.questionData.list || [];
          listData = [...listData, ...resData];
          this.questionData = { title: this.$route.query.name, list: listData };
          this.isLoading = false;
          this.$nextTick(() => {
            this.setImgStyle();
          });
        });
    },
    initCommonQuestion() {
      this.isLoading = true;
      this.$api
        .questionBase({
          papeid:
            this.$route.query.binddata && this.$route.query.binddata != 0
              ? this.$route.query.binddata
              : null || this.$route.query.id,
          type: this.$route.query.type,
          iscourse: this.$route.query.iscourse || 1,
        })
        .then((res) => {
          if (res.data) {
            this.getCommonQuestionType(res.data.id);
          } else {
            this.isEmpty = true;
            this.isLoading = false;
            this.accountRequest = 0;
            this.totalRequest = 0;
          }
        });
    },
    getCommonQuestionType(id) {
      this.$api
        .questionTitle({
          showToast: 1,
          papeinfoid: id,
        })
        .then((res) => {
          let resData = res.data || [];
          this.totalRequest = resData.length;
          if (resData.length) {
            for (let i = 0; i < resData.length; i++) {
              this.getCommonQuestionContent(resData, i);
            }
          } else {
            this.isEmpty = true;
            this.isLoading = false;
            this.accountRequest = 0;
            this.totalRequest = 0;
          }
        });
    },
    getCommonQuestionContent(data, i) {
      let content = data[i].content;
      this.$api
        .questionContent({
          showToast: 1,
          papetypeid: data[i].id,
        })
        .then((res) => {
          let resData = res.data;
          let needData = [];
          let need = true;
          this.accountRequest = ++this.accountRequest;
          if (resData.length) {
            resData.forEach((item, index) => {
              item.parentNum = i;
              item.answerVal = "";
              item.answerIdx = "";
              item.questionIdx = [i, index];
              item.showParse = false;
              item.showAnswer = false;

              if (["语文", "英语"].includes(this.currentCourse.name)) {
                item.optionjson = this.computeOptionjson(
                  this.formatAddPointWords(item.optionjson)
                );
                item.content = this.formatAddPointWords(content);
                item.testpapecontent = this.formatAddPointWords(
                  item.testpapecontent
                );
                item.rightkey = this.formatAddPointWords(item.rightkey);
                item.analysiscontent = this.formatAddPointWords(
                  item.analysiscontent
                );
              } else {
                item.optionjson = this.computeOptionjson(item.optionjson);
                item.content = content;
              }
              item.unknowNum = 0;
              item.testpapecontent = this.formatImg(item.testpapecontent);
              item.rightkey = this.formatImg(item.rightkey);
              item.analysiscontent = this.formatImg(item.analysiscontent);
              item.content = this.formatImg(item.content);
              item.testpapecontent = this.middleImg(item.testpapecontent);
              item.content = this.middleImg(item.content);
              item.rightkey = this.middleImg(item.rightkey);
              item.analysiscontent = this.middleImg(item.analysiscontent);

              if (item.type == 9) {
                let reg = new RegExp(item.mubiaozi);
                let text = this.html2string(item.testpapecontent);
                let space = "";
                for (let i = 0; i < item.mubiaozi.length; i++) {
                  space = space + " ";
                }
                item.testpapecontent = text.replace(reg, space);
              }
              item.content = this.setMathBa(item.content);
              item.testpapecontent = this.setMathBa(item.testpapecontent);
              item.rightkey = this.setMathBa(item.rightkey);
              item.analysiscontent = this.setMathBa(item.analysiscontent);
              item.testpapecontent = this.setMathBa(item.testpapecontent);

              if (["英语"].includes(this.currentCourse.name)) {
                if (this.currentTitle == "拼写") {
                  item.type = 13;
                  //need = this.palindrome(item.testpapecontent);
                }
              }
              if (need) {
                needData.push(item);
              }
            });
            this.$set(this.requestData, i, needData);
          }
        });
    },
  },
};
</script>

<style lang="scss">
.body-con {
  p {
    text-justify: auto;
  }
}
</style>

<style lang="scss" scoped>
.question-article {
  font-size: 0.3rem;
  line-height: 1.6;
  padding-bottom: 0.3rem;
}
.loading-box {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 1.28rem;
  z-index: 1000;
  background: #fff;
  font-size: 0.28rem;
}
.hearing-btn {
  position: fixed;
  bottom: 1.5rem;
  right: 0.3rem;
  z-index: 100;
  width: 0.6rem;
  height: 0.6rem;
  overflow: hidden;
  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}
.status-vioce {
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  width: 0;
  height: 0;
  overflow: hidden;
}
.question-handle {
  position: fixed;
  left: -0.15rem;
  right: -0.15rem;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.28rem;
  background: #fff;

  .btn {
    flex: 1;
    margin: 0 0.15rem;
    height: 0.68rem;
    background-size: cover;
    background-repeat: no-repeat;
    border: 0.02rem solid #28b295;
    border-radius: 0.34rem;
    text-align: center;
    line-height: 0.68rem;
    font-size: 0.26rem;
    font-weight: 400;
    color: #333;
    background: #fff;
    &:active {
      background: rgba(40, 178, 149, 0.1);
    }
    &.disable {
      border-color: #e1e1e1;
      background: #f1f1f1;
    }
  }
}
.common-step {
  display: flex;
  align-items: flex-end;
  background: #fff;
  line-height: 1;
  .current {
    font-size: 0.52rem;
    color: #36ba9e;
    transform: translateY(0.03rem);
  }
  .total {
    font-size: 0.3rem;
    color: #969799;
  }
}
.common-progress {
  position: fixed;
  left: 0;
  right: 0;
  top: 1.08rem;
  height: 0.18rem;
  z-index: 100;
  background: #fafafa;
  .con {
    height: 0.18rem;
    background: #36ba9e;
  }
}
.empty-box {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content-body {
  position: fixed;
  left: 0;
  right: 0;
  top: 1.28rem;
  bottom: 1rem;
  background: #fff;
  padding: 0.3rem 0 0.3rem 0.3rem;
  .body-con {
    height: 100%;
    padding: 0 0.3rem 0 0;
    overflow: hidden;
    overflow-y: auto;
  }
}
.question-content {
  height: 100vh;
  background: #fafafa;
}
</style>
import { render, staticRenderFns } from "./wordsBreakThrough.vue?vue&type=template&id=2ad7dadc&scoped=true&"
import script from "./wordsBreakThrough.vue?vue&type=script&lang=js&"
export * from "./wordsBreakThrough.vue?vue&type=script&lang=js&"
import style0 from "./wordsBreakThrough.vue?vue&type=style&index=0&id=2ad7dadc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ad7dadc",
  null
  
)

export default component.exports